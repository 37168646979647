.tabs {
  .tab-content {
    min-height: 300px;

    &.tab-details {
      display: flex;
      flex-direction: row;
    }

    &.tab-hologram {
      display: flex;
      flex-direction: column;

      span:first-child {
        align-self: flex-end;
      }
      .ant-card {
        align-self: flex-start;
      }
    }

    .ant-upload {
      margin-bottom: 8px;
    }

    .ant-card {
      .ant-card-actions {
        height: 48px;
        min-width: 250px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        li {
          width: 24px !important;
          margin-right: 24px;
        }
      }
    }
  }

  &.increased-height {
    .tab-content {
      min-height: 700px;
    }
  }
}

.security-feature-images {
  margin-right: 24px;
  padding-right: 8px;
  border-right: 1px solid rgb(235, 237, 240);
  box-sizing: content-box;

  .security-feature-image-list {
    width: 536px;
    max-height: 700px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;

    .ant-card {
      margin: 0 16px 16px 0;

      .ant-card-body {
        padding: 0;
      }
    }

    .security-feature-image-container {
      width: 250px;
      height: 202px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 250px;
        max-height: 202px;
      }
    }
  }

  .ant-upload-select {
    float: right;
    margin-right: 16px;
  }

  &.scroll-mode {
    border-right: none;

    .security-feature-image-list {
      width: 553px;
      overflow: auto;
    }

    .ant-upload-select {
      margin-right: 32px;
    }
  }
}

.security-feature-details {
  flex-grow: 1;
}

.dummy-image {
  width: 250px;
  height: 250px;
  border: 1px solid #f0f0f0;
}
