.previewContainer {
  position: absolute;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  max-height: 600px;
}

.previewImage {
  position: absolute;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.ReactCrop__image {
  display: inline-flex;
}

.preview {
  overflow: hidden;
}