.hologram {
  position: relative;

  img {
    position: absolute;
  }
}

.visible {
  opacity: 1;
}

.invisible {
  opacity: 0;
}

.image-warning {
  font-size: 10px;
  color: red;
}

:global(.ant-result + div) {
  display: none;
}
