.multilingualInput {
  :global(.ant-tabs-tab) {
    justify-content: center;
    padding-bottom: 0 !important;
    line-height: 30px !important;
    height: 32px !important;
    width: 42px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    padding-top: 0px !important;

    text-align: center;

    &::before {
      border-top: 0px !important;
    }
  }

  :global(.ant-tabs-tab-active) {
    background-color: rgb(24, 144, 255) !important;
    color: white !important;
  }

  :global(.ant-tabs-tab-btn) {
    color: inherit !important;
  }

  .hidden-tabs {
    :global(.ant-tabs-bar) {
      display: none;
    }
  }
}
