.notes {
  height: 100px;
  border: solid 1px #404040;
  margin-bottom: 5px;
  padding: 5px;
}

.scanOrder {
  width: 100%;
}

.scanOrder td,
.scanOrder th {
  border: 1px solid #404040;
  padding: 0.5em;
}

.scanOrder .titleRow {
  border: 0;
  padding-left: 0;
  padding-bottom: 0;
  height: 50px;
  vertical-align: bottom;
}

.scanOrder .multiRowColumn {
  vertical-align: top;
}
